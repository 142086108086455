import { FunctionComponent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { RootState } from '../reducer';
import { clearToaster } from './actions';

export const ToastMessageHandler: FunctionComponent = () => {
  const { message, type } = useSelector(
    (state: RootState) => state.toaster.toast,
  );
  const dispatch = useDispatch();

  const { addToast } = useToasts();
  useEffect(() => {
    if (message && message.length) {
      addToast(message, { appearance: type, autoDismiss: true });
      setTimeout(() => dispatch(clearToaster()));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message]);
  return null;
};
