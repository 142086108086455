import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import {
  CohortActionTypes,
  GET_COHORT_INFORMATION,
  GET_COHORT_INFORMATION_FAILED,
  GET_COHORT_INFORMATION_SUCCEEDED,
  RESET_COHORT_INFORMATION,
} from './action';
import {CohortInformation} from './types';

dayjs.extend(utc);
dayjs.extend(timezone);

interface ProfileState {
  loadingCohortInformation: boolean;
  cohortInformation?: CohortInformation;
  cohortDay: number;
}

export const initialState: ProfileState = {
  loadingCohortInformation: false,
  cohortInformation: undefined,
  cohortDay: 0,
};

export const cohortReducer = (
  state = initialState,
  action: CohortActionTypes,
): ProfileState => {
  switch (action.type) {
    case GET_COHORT_INFORMATION:
      return {
        ...state,
        loadingCohortInformation: true,
      };
    case GET_COHORT_INFORMATION_SUCCEEDED:
      const {
        _id,
        name,
        status,
        startDate,
        endDate,
        facebookGroupLink,
      } = action.payload.cohortInformation;
      let day = -1;
      if (status === 'no-cohort') {
        day = dayjs().diff(dayjs('2001'), 'day');
      }
      if (status === 'found-cohort' && startDate) {
        day = dayjs().diff(startDate, 'day') + 1;
      }
      return {
        ...state,
        loadingCohortInformation: false,
        cohortInformation: {
          _id,
          name,
          status,
          startDate: dayjs(startDate),
          endDate: dayjs(endDate),
          facebookGroupLink,
        },
        cohortDay: day,
      };
    case GET_COHORT_INFORMATION_FAILED:
      return {
        ...state,
        loadingCohortInformation: false,
      };
    case RESET_COHORT_INFORMATION:
      return {...initialState};
    default:
      return state;
  }
};
