export const AUTH_FEDERATE = 'AUTH_FEDERATE';
export const AUTH_FEDERATE_SUCCEEDED = 'AUTH_FEDERATE_SUCCEEDED';
export const AUTH_FEDERATE_FAILED = 'AUTH_FEDERATE_FAILED';
export const AUTH_LOAD = 'AUTH_LOAD';
export const AUTH_LOAD_SUCCEEDED = 'AUTH_LOAD_SUCCEEDED';
export const AUTH_LOAD_FAILED = 'AUTH_LOAD_FAILED';
export const AUTH_WITH_EMAIL = 'AUTH_WITH_EMAIL';
export const AUTH_WITH_EMAIL_SUCCEEDED = 'AUTH_WITH_EMAIL_SUCCEEDED';
export const AUTH_WITH_EMAIL_FAILED = 'AUTH_WITH_EMAIL_FAILED';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_LOGOUT_SUCCEEDED = 'AUTH_LOGOUT_SUCCEEDED';
export const AUTH_LOGOUT_FAILED = 'AUTH_LOGOUT_FAILED';

interface AuthFederate {
  type: typeof AUTH_FEDERATE;
}

interface AuthFederateSucceeded {
  type: typeof AUTH_FEDERATE_SUCCEEDED;
}

interface AuthFederateFailed {
  type: typeof AUTH_FEDERATE_FAILED;
}

interface AuthWithEmail {
  type: typeof AUTH_WITH_EMAIL;
}

interface AuthWithEmailSucceeded {
  type: typeof AUTH_WITH_EMAIL_SUCCEEDED;
}

interface AuthWithEmailFailed {
  type: typeof AUTH_WITH_EMAIL_FAILED;
}
interface AuthLoad {
  type: typeof AUTH_LOAD;
}

interface AuthLoadSucceeded {
  type: typeof AUTH_LOAD_SUCCEEDED;
}

interface AuthLoadFailed {
  type: typeof AUTH_LOAD_FAILED;
}

interface AuthLogout {
  type: typeof AUTH_LOGOUT;
}

interface AuthLogoutSucceeded {
  type: typeof AUTH_LOGOUT_SUCCEEDED;
}

interface AuthLogoutFailed {
  type: typeof AUTH_LOGOUT_FAILED;
}

export type AuthActionTypes =
  | AuthFederate
  | AuthFederateSucceeded
  | AuthFederateFailed
  | AuthWithEmail
  | AuthWithEmailSucceeded
  | AuthWithEmailFailed
  | AuthLoad
  | AuthLoadSucceeded
  | AuthLoadFailed
  | AuthLogout
  | AuthLogoutSucceeded
  | AuthLogoutFailed;

export function authFederate(): AuthActionTypes {
  return {
    type: AUTH_FEDERATE,
  };
}

export function authFederateSucceeded(): AuthActionTypes {
  return {
    type: AUTH_FEDERATE_SUCCEEDED,
  };
}

export function authFederateFailed(): AuthActionTypes {
  return {
    type: AUTH_FEDERATE_FAILED,
  };
}

export function authWithEmail(): AuthActionTypes {
  return {
    type: AUTH_WITH_EMAIL,
  };
}

export function authWithEmailSucceeded(): AuthActionTypes {
  return {
    type: AUTH_WITH_EMAIL_SUCCEEDED,
  };
}

export function authWithEmailFailed(): AuthActionTypes {
  return {
    type: AUTH_WITH_EMAIL_FAILED,
  };
}

export function authLoad(): AuthActionTypes {
  return {
    type: AUTH_LOAD,
  };
}

export function authLoadSucceeded(): AuthActionTypes {
  return {
    type: AUTH_LOAD_SUCCEEDED,
  };
}

export function authLoadFailed(): AuthActionTypes {
  return {
    type: AUTH_LOAD_FAILED,
  };
}

export function authLogout(): AuthActionTypes {
  return {
    type: AUTH_LOGOUT,
  };
}

export function authLogoutSucceeded(): AuthActionTypes {
  return {
    type: AUTH_LOGOUT_SUCCEEDED,
  };
}

export function authLogoutFailed(): AuthActionTypes {
  return {
    type: AUTH_LOGOUT_FAILED,
  };
}
