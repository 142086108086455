import React, { lazy, FunctionComponent, Suspense, useEffect } from "react";
import {
  BrowserRouter,
  Switch,
  Route,
  Redirect,
  RouteProps,
} from "react-router-dom";
import { applyMiddleware, createStore } from "redux";
import { Provider, useDispatch, useSelector } from "react-redux";
import thunk from "redux-thunk";
import { ToastProvider } from "react-toast-notifications";
import firebase from "firebase/app";
import "firebase/auth";

import { rootReducer, RootState } from "./reducer";
import "./vendors/firebase";
import { ToastMessageHandler } from "./toaster";
import { SetAuthorizationHeader } from "./vendors/axios";
import { authLoadSucceeded, authLoadFailed } from "./auth/actions";

const Auth = lazy(() => import("./auth"));
const Join = lazy(() => import("./cohort/join"));

const store = createStore(rootReducer, applyMiddleware(thunk));

const renderLoader = () => <></>;

const InnerApp: FunctionComponent = () => {
  const dispatch = useDispatch();
  const { authenticated, authenticating } = useSelector(
    (state: RootState) => state.auth
  );

  function PrivateRoute({ children, ...rest }: RouteProps) {
    console.log(authenticating, authenticated);
    return (
      <Route
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...rest}
        render={({ location }) =>
          (authenticated && !authenticating) ||
          (authenticated && authenticating) ? (
            children
          ) : !authenticated && authenticating ? (
            <></>
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: location },
              }}
            />
          )
        }
      />
    );
  }

  useEffect(() => {
    if (authenticated) {
      // put in the information that we want to load when the user opens the dashboard
    }
  }, [authenticated, dispatch]);

  return (
    <>
      <Switch>
        <PrivateRoute path="/join">
          <Suspense fallback={renderLoader()}>
            <Join />
          </Suspense>
        </PrivateRoute>
        <Route path="/">
          <Suspense fallback={renderLoader()}>
            <Auth />
          </Suspense>
        </Route>
      </Switch>
    </>
  );
};

const App: FunctionComponent = () => {
  const onChange = async (changeEvent: null | firebase.User) => {
    try {
      if (changeEvent) {
        const token = await firebase.auth().currentUser?.getIdToken();
        if (changeEvent && token) {
          SetAuthorizationHeader(token);
          store.dispatch(authLoadSucceeded());
        } else {
          store.dispatch(authLoadFailed());
        }
      }
    } catch (e) {
      store.dispatch(authLoadFailed());
    }
  };

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(onChange);
    return () => unsubscribe();
  }, []);

  return (
    <Provider store={store}>
      <ToastProvider>
        <ToastMessageHandler />
      </ToastProvider>

      <BrowserRouter>
        <InnerApp />
      </BrowserRouter>
    </Provider>
  );
};

export default App;
