import { CohortInformation } from "./types";

export const RESET_COHORT_INFORMATION = "RESET_COHORT_INFORMATION";
export const GET_COHORT_INFORMATION = "GET_COHORT_INFORMATION";
export const GET_COHORT_INFORMATION_SUCCEEDED =
  "GET_COHORT_INFORMATION_SUCCEEDED";
export const GET_COHORT_INFORMATION_FAILED = "GET_COHORT_INFORMATION_FAILED";
export const SUBMIT_COHORT_INFORMATION = "SUBMIT_COHORT_INFORMATION";
export const SUBMIT_COHORT_INFORMATION_SUCCEEDED =
  "SUBMIT_COHORT_INFORMATION_SUCCEEDED";
export const SUBMIT_COHORT_INFORMATION_FAILED =
  "SUBMIT_COHORT_INFORMATION_FAILED";

export const OPT_IN_USER = "OPT_IN_USER";
export const OPT_IN_USER_SUCCEEDED = "OPT_IN_USER_SUCCEEDED";
export const OPT_IN_USER_FAILED = "OPT_IN_USER_FAILED";

interface ResetCohortInformation {
  type: typeof RESET_COHORT_INFORMATION;
}

interface GetCohortInformation {
  type: typeof GET_COHORT_INFORMATION;
}

interface GetCohortInformationSucceeded {
  type: typeof GET_COHORT_INFORMATION_SUCCEEDED;
  payload: {
    cohortInformation: CohortInformation;
  };
}

interface GetCohortInformationFailed {
  type: typeof GET_COHORT_INFORMATION_FAILED;
}

interface SubmitCohortInformation {
  type: typeof SUBMIT_COHORT_INFORMATION;
}

interface SubmitCohortInformationSucceeded {
  type: typeof SUBMIT_COHORT_INFORMATION_SUCCEEDED;
  payload: {
    cohortInformation: CohortInformation;
  };
}

interface SubmitCohortInformationFailed {
  type: typeof SUBMIT_COHORT_INFORMATION_FAILED;
}

interface OptInUser {
  type: typeof OPT_IN_USER;
}

interface OptInUserSucceeded {
  type: typeof OPT_IN_USER_SUCCEEDED;
}

interface OptInUserFailed {
  type: typeof OPT_IN_USER_FAILED;
}

export type CohortActionTypes =
  | ResetCohortInformation
  | GetCohortInformation
  | GetCohortInformationSucceeded
  | GetCohortInformationFailed
  | SubmitCohortInformation
  | SubmitCohortInformationSucceeded
  | SubmitCohortInformationFailed
  | OptInUser
  | OptInUserSucceeded
  | OptInUserFailed;

export function resetCohortInformation(): CohortActionTypes {
  return {
    type: "RESET_COHORT_INFORMATION",
  };
}

export function getCohortInformation(): CohortActionTypes {
  return {
    type: "GET_COHORT_INFORMATION",
  };
}

export function getCohortInformationSucceeded(
  cohortInformation: CohortInformation
): CohortActionTypes {
  return {
    type: "GET_COHORT_INFORMATION_SUCCEEDED",
    payload: {
      cohortInformation,
    },
  };
}

export function getCohortInformationFailed(): CohortActionTypes {
  return {
    type: "GET_COHORT_INFORMATION_FAILED",
  };
}

export function submitCohortInformation(): CohortActionTypes {
  return {
    type: "SUBMIT_COHORT_INFORMATION",
  };
}

export function submitCohortInformationSucceeded(
  cohortInformation: CohortInformation
): CohortActionTypes {
  return {
    type: "SUBMIT_COHORT_INFORMATION_SUCCEEDED",
    payload: {
      cohortInformation,
    },
  };
}

export function submitCohortInformationFailed(): CohortActionTypes {
  return {
    type: "SUBMIT_COHORT_INFORMATION_FAILED",
  };
}

export function optInUser(): CohortActionTypes {
  return {
    type: "OPT_IN_USER",
  };
}

export function optInUserSucceeded(): CohortActionTypes {
  return {
    type: "OPT_IN_USER_SUCCEEDED",
  };
}

export function optInUserFailed(): CohortActionTypes {
  return {
    type: "OPT_IN_USER_FAILED",
  };
}
