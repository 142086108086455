// this file if to combine the reducers all in one
import {combineReducers} from 'redux';
import {authReducer} from './auth/reducer';
import {cohortReducer} from './cohort/reducer';
import { toasterReducer } from './toaster/reducer';

export const rootReducer = combineReducers({
  auth: authReducer,
  cohort: cohortReducer,
  toaster: toasterReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
