import {
  AuthActionTypes,
  AUTH_FEDERATE,
  AUTH_FEDERATE_SUCCEEDED,
  AUTH_FEDERATE_FAILED,
  AUTH_WITH_EMAIL,
  AUTH_WITH_EMAIL_SUCCEEDED,
  AUTH_WITH_EMAIL_FAILED,
  AUTH_LOAD_FAILED,
  AUTH_LOAD_SUCCEEDED,
  AUTH_LOGOUT_SUCCEEDED,
} from './actions';

interface AuthState {
  authenticated: boolean;
  authenticating: boolean;
}

export const initialState: AuthState = {
  authenticated: false,
  authenticating: false,
};

export const authReducer = (
  state = initialState,
  action: AuthActionTypes,
): AuthState => {
  switch (action.type) {
    case AUTH_FEDERATE:
    case AUTH_WITH_EMAIL:
      return {
        ...state,
        authenticating: true,
        authenticated: false,
      };
    case AUTH_LOAD_SUCCEEDED:
    case AUTH_WITH_EMAIL_SUCCEEDED:
    case AUTH_FEDERATE_SUCCEEDED:
      return {
        authenticating: false,
        authenticated: true,
      };
    case AUTH_LOAD_FAILED:
    case AUTH_WITH_EMAIL_FAILED:
    case AUTH_FEDERATE_FAILED:
      return {
        authenticating: false,
        authenticated: false,
      };
    case AUTH_LOGOUT_SUCCEEDED:
      return {
        authenticated: false,
        authenticating: false,
      };
    default:
      return state;
  }
};
